import { useEffect, useState } from 'react';
import { useDebounce } from 'site-modules/shared/hooks/use-debounce';
import { EdmundsAPI } from 'client/data/api/api-client';
import { getMetricApiOptions } from 'client/utils/metrics-hoc';

const createResultsFetcher = () => {
  const controller = new AbortController();

  return {
    abort() {
      controller.abort();
    },
    isAborted() {
      return controller.signal.aborted;
    },
    fetchResults(query, options) {
      const requestOptions = {
        method: 'POST',
        headers: {
          ...options.headers,
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          question: query,
        }),
        signal: controller.signal,
      };

      return EdmundsAPI.fetchJson('/llm/v1/hot/mmt/', requestOptions);
    },
  };
};

const DEFAULT_API_METRICS = {};

export const useGlobalSearchResults = (searchQuery, { delay = 300, apiMetrics = DEFAULT_API_METRICS } = {}) => {
  const [results, setResults] = useState();
  const [delayedQuery, setDelayedQuery] = useState(searchQuery);
  const [isLoading, setIsLoading] = useState(false);

  const setDebouncedDelayedQuery = useDebounce(setDelayedQuery, delay);

  useEffect(() => {
    setDebouncedDelayedQuery(searchQuery);
  }, [searchQuery, setDebouncedDelayedQuery]);

  useEffect(() => {
    const { abort, isAborted, fetchResults } = createResultsFetcher();

    if (delayedQuery) {
      const fetchGlobalSearchResults = async () => {
        setIsLoading(true);
        try {
          const searchResults = await fetchResults(delayedQuery, getMetricApiOptions(apiMetrics));
          setResults(searchResults);
          setIsLoading(false);
        } catch (e) {
          if (isAborted()) {
            return;
          }
          setResults(null);
          setIsLoading(false);
        }
      };

      fetchGlobalSearchResults();
    }

    return () => {
      abort?.();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delayedQuery]);

  return { results, isLoading };
};
