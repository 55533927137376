import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'reactstrap/lib/Button';
import { Link } from 'site-modules/shared/components/link/link';
import {
  BUTTON_DATA,
  ERROR_SCREEN_CREATIVE_ID,
  INPUT_DELAY,
  PRIMING_BOX_COPY,
  SELECT_OPTION,
} from 'site-modules/shared/constants/global-search/global-search';
import { GlobalSearchLinks } from 'site-modules/shared/components/inventory/global-search/global-search-links/global-search-links';
import { GlobalSearchLoadingSeparator } from 'site-modules/shared/components/inventory/global-search/global-search-loading-separator/global-search-loading-separator';
import { useDebounce } from 'site-modules/shared/hooks/use-debounce';
import {
  fireTemporaryClickTracking,
  fireWidgetViewTracking,
} from 'site-modules/shared/utils/inventory/global-search-tracking';
import { useDialogFocusTrap } from 'site-modules/shared/hooks/global-search/use-dialog-focus-trap';

import './global-search-results.scss';

export const GlobalSearchResults = memo(
  ({
    searchQuery,
    isMobile,
    isTyping,
    isError,
    isOpen,
    fastMatcherData,
    isFastMatcherLoading,
    searchId,
    isSelectOptionMessageShown,
  }) => {
    const trimmedQuery = searchQuery.trim();
    const [isResultsLoading, setIsResultsLoading] = useState(false);
    const debouncedIsLoading = useDebounce(setIsResultsLoading, INPUT_DELAY);
    const searchResultsRef = useRef(null);

    useDialogFocusTrap(searchResultsRef);

    useEffect(() => {
      if (isFastMatcherLoading) {
        setIsResultsLoading(isFastMatcherLoading);
      } else {
        debouncedIsLoading(isFastMatcherLoading);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFastMatcherLoading]);

    useEffect(() => {
      if (isMobile && !searchQuery) {
        fireWidgetViewTracking({ value: PRIMING_BOX_COPY });
      }
    }, [isMobile, searchQuery]);

    useEffect(() => {
      if (!isMobile && isOpen && !searchQuery) {
        fireWidgetViewTracking({ value: PRIMING_BOX_COPY });
      }
    }, [isMobile, isOpen, searchQuery]);

    useEffect(() => {
      if (isError) {
        fireWidgetViewTracking({ creativeId: ERROR_SCREEN_CREATIVE_ID, value: `srp_search_id=${searchId}` });
      }
    }, [isError, searchId]);

    useEffect(() => {
      if (isSelectOptionMessageShown) {
        fireWidgetViewTracking({ value: SELECT_OPTION });
      }
    }, [isSelectOptionMessageShown]);

    const quickLinks = (
      <ul
        className={classnames('quick-links list-unstyled mb-0 d-flex flex-column align-items-start', {
          'px-0_5': !isMobile,
        })}
      >
        <h3 className={classnames('medium font-weight-bold text-cool-gray-10 py-0_5 mb-0', { 'pl-0_5': !isMobile })}>
          Quick Links
        </h3>
        {BUTTON_DATA.map(({ id, label, url, dataTrackingId }) => (
          <li className="w-100" key={id}>
            <Button
              key={id}
              tag={Link}
              color="outline"
              to={url}
              className={classnames(
                'quick-link text-cool-gray-30 text-transform-none text-underline py-0_75 px-0 size-16',
                {
                  'pl-0_5 w-100 text-left': !isMobile,
                }
              )}
              data-tracking-id={dataTrackingId}
            >
              {label}
            </Button>
          </li>
        ))}
      </ul>
    );

    const selectOptionMessage = (
      <h3 role="alert" className="bg-blue-100 text-cool-gray-30 heading-5 px-1 py-1 mx-0 mx-md-0_5 rounded-16">
        {SELECT_OPTION}
      </h3>
    );

    return (
      <div
        ref={searchResultsRef}
        className={classnames('global-search-results pos-r', {
          'pt-0_5 pt-md-1': trimmedQuery || !isMobile || !isError,
        })}
      >
        <GlobalSearchLoadingSeparator
          isMobile={isMobile}
          showAnimation={!!trimmedQuery && (isTyping || isResultsLoading)}
        />

        {isError && (
          <>
            <div className="mb-1_5 mt-md-1 px-md-1">
              <h3 className="heading-5">Please try adjusting your search</h3>
              <p className="size-16">No results for &quot;{searchQuery}&quot;</p>
            </div>
            {quickLinks}
          </>
        )}

        {!trimmedQuery && !isError && (
          <>
            <div
              className={classnames({
                'px-0_5': !isMobile,
              })}
            >
              <h3 className="priming-box heading-5 font-weight-normal text-blue-10 font-italic py-1 px-1 mb-1 rounded-16">
                {isMobile && <span className="icon-sparkles icon mr-0_5 pt-0_25 medium" aria-hidden />}
                {PRIMING_BOX_COPY}
              </h3>
            </div>
            {isSelectOptionMessageShown && selectOptionMessage}

            {quickLinks}
          </>
        )}

        {trimmedQuery && !isError && (
          <div aria-live="polite" aria-atomic>
            {isSelectOptionMessageShown && selectOptionMessage}

            <button
              className={classnames(
                'search-button w-100 d-flex align-items-center py-0_75 border-0 bg-transparent rounded-8',
                {
                  'px-0_5': !isMobile,
                  'px-0': isMobile,
                }
              )}
              type="submit"
              onClick={fireTemporaryClickTracking}
            >
              <span className="gradient-circle d-flex align-items-center justify-content-center align-self-start rounded-circle mr-1">
                <span className="icon-magic-search icon medium" aria-hidden />
              </span>
              <span className="d-flex text-cool-gray-30">
                <b>search&nbsp;</b>
                <span className="text-left font-weight-normal">&quot;{trimmedQuery}&quot;</span>
              </span>
            </button>
            {!!fastMatcherData && (
              <GlobalSearchLinks query={trimmedQuery} fastMatcherData={fastMatcherData} isMobile={isMobile} />
            )}
          </div>
        )}
      </div>
    );
  }
);

GlobalSearchResults.displayName = 'GlobalSearchResults';

GlobalSearchResults.propTypes = {
  searchQuery: PropTypes.string,
  isMobile: PropTypes.bool,
  isTyping: PropTypes.bool,
  isError: PropTypes.bool,
  isOpen: PropTypes.bool,
  isSelectOptionMessageShown: PropTypes.bool,
  fastMatcherData: PropTypes.shape({
    delta: PropTypes.string,
    struct: PropTypes.shape({}),
  }),
  isFastMatcherLoading: PropTypes.bool,
  searchId: PropTypes.string,
};

GlobalSearchResults.defaultProps = {
  searchQuery: '',
  isMobile: false,
  isTyping: false,
  isError: false,
  isOpen: false,
  isSelectOptionMessageShown: false,
  fastMatcherData: null,
  isFastMatcherLoading: false,
  searchId: '',
};
