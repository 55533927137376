import React, { useState, useCallback, useEffect, useDeferredValue, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Form from 'reactstrap/lib/Form';
import Button from 'reactstrap/lib/Button';
import { flow } from 'lodash';
import { FocusScope, FocusRing } from '@react-aria/focus';
/* Utils */
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { getStaticImageUrl } from 'client/utils/image-helpers';
import { transformSuggestedFilters } from 'site-modules/shared/utils/inventory/semantic-search';
import {
  fireAbandonTracking,
  fireResetTracking,
  fireSearchLinkTracking,
  fireSelectAutocompleteTracking,
  fireTemporaryKeydownTracking,
} from 'site-modules/shared/utils/inventory/global-search-tracking';
import { ApiMetrics, withMetrics } from 'client/utils/metrics-hoc';
/* Hooks */
import { useDebounce } from 'site-modules/shared/hooks/use-debounce';
import { useLlmResults } from 'site-modules/shared/hooks/global-search/use-llm-results';
import { useGlobalSearchResults } from 'site-modules/shared/hooks/global-search/use-global-search-results';
/* Models */
import { buildSearchIdPath, SemanticSearchModel } from 'client/data/models/semantic-search';
import { InventoryEntities } from 'client/data/models/inventory';
/* Constants */
import {
  AUTOSIZED_AREA_LENGTH,
  CLEAR_INPUT,
  CREATIVE_ID,
  INPUT_DELAY,
  INPUT_MIN_HEIGHT,
  LISTBOX_ID,
  LOOKING_FOR_PLACEHOLDER,
  OPEN_DELAY,
} from 'site-modules/shared/constants/global-search/global-search';
import { KEY_CODES } from 'site-modules/shared/components/home-vehicle-search-autocomplete/home-vehicle-search-autocomplete';
import { TrackingConstant } from 'client/tracking/constant';
/* Components */
import { Collapse } from 'site-modules/shared/components/collapse/collapse';
import { RestoreFocus } from 'site-modules/shared/components/restore-focus/restore-focus';
import { AutosizedArea } from 'site-modules/shared/components/inventory/autosized-area/autosized-area';
import { GlobalSearchDrawer } from 'site-modules/shared/components/inventory/global-search/global-search-drawer/global-search-drawer';
import { GlobalSearchDropdown } from 'site-modules/shared/components/inventory/global-search/global-search-dropdown/global-search-dropdown';

import './global-search.scss';

function GlobalSearchUI({
  isMobile,
  setModelValue,
  suggestedFilters,
  searchId,
  apiMetrics,
  withEnterSubmitDisabled,
  onSearchSubmit,
  closeOnSubmit,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [isSelectOptionMessageShown, setIsSelectOptionMessageShown] = useState(false);
  const debouncedSetIsOpened = useDebounce(setIsOpen, OPEN_DELAY);
  const debouncedIsTyping = useDebounce(setIsTyping, INPUT_DELAY);
  const deferredQuery = useDeferredValue(query);
  const trimmedQuery = deferredQuery?.trim();
  const { triggerSubmit, triggerChange, isLoading, isError, loadingComponent } = useLlmResults({
    suggestedFilters,
    searchId,
    setModelValue,
    query: trimmedQuery,
  });
  const { results: fastMatcherData, isLoading: isFastMatcherLoading } = useGlobalSearchResults(trimmedQuery, {
    apiMetrics,
    delay: INPUT_DELAY,
  });
  const prevSearchQuery = useRef('');

  const handleChange = useCallback(
    async value => {
      setQuery(value);
      setIsTyping(true);
      debouncedSetIsOpened(true);
      debouncedIsTyping(false);
      setIsSelectOptionMessageShown(false);
      await triggerChange();
    },
    [debouncedSetIsOpened, debouncedIsTyping, triggerChange]
  );

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();
      fireSearchLinkTracking({
        input: trimmedQuery,
        fastMatcherData,
        value: `search ${trimmedQuery}`,
        selectionType: 'search',
      });
      setIsSelectOptionMessageShown(false);

      if (onSearchSubmit) {
        onSearchSubmit(trimmedQuery);
      } else {
        await triggerSubmit(trimmedQuery);
      }

      if (closeOnSubmit) {
        setIsOpen(false);
      }
    },
    [trimmedQuery, fastMatcherData, onSearchSubmit, closeOnSubmit, triggerSubmit]
  );

  const handleClick = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClickOutside = useCallback(() => {
    setIsOpen(false);
    setIsSelectOptionMessageShown(false);
    fireAbandonTracking({ input: trimmedQuery, fastMatcherData });
  }, [trimmedQuery, fastMatcherData]);

  const handleEscKeyDown = useCallback(
    event => {
      if (event.keyCode === KEY_CODES.escape) {
        handleClickOutside(event);
      }
    },
    [handleClickOutside]
  );

  const handleKeyDown = useCallback(
    async event => {
      if (event.keyCode === KEY_CODES.enter) {
        event.preventDefault();
        if (withEnterSubmitDisabled) {
          setIsSelectOptionMessageShown(true);
        } else if (prevSearchQuery.current !== trimmedQuery) {
          prevSearchQuery.current = trimmedQuery;
          await handleSubmit(event);
        }
      }

      fireTemporaryKeydownTracking(event);
    },
    [handleSubmit, trimmedQuery, withEnterSubmitDisabled]
  );

  const onQueryCancel = useCallback(async () => {
    setQuery('');
    setIsSelectOptionMessageShown(false);
    await triggerChange();
  }, [triggerChange]);

  const handleQueryCancel = useCallback(async () => {
    await onQueryCancel();
    fireResetTracking({ input: trimmedQuery, fastMatcherData });
  }, [onQueryCancel, trimmedQuery, fastMatcherData]);

  const handleOnDrawerCancel = useCallback(async () => {
    setIsOpen(false);
    await onQueryCancel();
  }, [onQueryCancel]);

  const handleAfterDrawerSubmit = useCallback(() => {
    if (closeOnSubmit) {
      setIsOpen(false);
    }
  }, [closeOnSubmit]);

  useEffect(() => {
    if (isOpen) {
      fireSelectAutocompleteTracking({
        eventType: TrackingConstant.EVENT_TYPE_ACTION_START,
        value: LOOKING_FOR_PLACEHOLDER,
      });
      fireSelectAutocompleteTracking({ eventType: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS });
    }
  }, [isOpen]);

  return (
    <div className="global-search pos-r w-100 d-flex justify-content-center px-1 mb-1_5">
      {!isMobile && isOpen && (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          onClick={handleClickOutside}
          className={classnames('page-overlay top-0 right-0 bottom-0 left-0', {
            show: isOpen,
          })}
          data-testid="page-overlay"
        />
      )}
      <RestoreFocus isInnerScopeActive={isOpen}>
        <FocusScope contain={isOpen}>
          <FocusRing within focusRingClass="focus-visible">
            <div
              className={classnames('global-search-form-container pos-a', {
                'w-100 mobile': isMobile,
                'is-open': isOpen,
              })}
            >
              <div className="bg-white global-search-form-wrapper">
                <Form
                  id="global-search-form"
                  name="global-search-form"
                  noValidate
                  className="global-search-form pos-r"
                  onSubmit={handleSubmit}
                  onKeyDown={handleEscKeyDown}
                  data-tracking-parent={CREATIVE_ID}
                >
                  <div className="pos-r">
                    <div className="search-icon pos-a d-flex justify-content-center align-items-center h-100">
                      <img src={getStaticImageUrl('/icons/magic-search.svg')} alt="" decoding="async" loading="lazy" />
                    </div>
                    <AutosizedArea
                      className={classnames('global-search-input size-16', { 'hide-caret': isMobile && isOpen })}
                      onChange={handleChange}
                      onClick={handleClick}
                      onKeyDown={handleKeyDown}
                      hintText={LOOKING_FOR_PLACEHOLDER}
                      inputValue={query}
                      inputMinHeight={INPUT_MIN_HEIGHT}
                      maxLength={AUTOSIZED_AREA_LENGTH}
                      disableOutline
                      role="combobox"
                      aria-expanded={isOpen}
                      aria-haspopup="dialog"
                      aria-controls={LISTBOX_ID}
                      aria-label="Search:"
                    />
                  </div>
                  {!!query && !isMobile && isOpen && (
                    <Button
                      onClick={handleQueryCancel}
                      className="query-cancel-button pos-a p-0 border-0 background-none d-flex justify-content-center align-items-center"
                    >
                      <i className="text-cool-gray-50 icon-cross2 small" role="img" aria-label={CLEAR_INPUT} />
                    </Button>
                  )}
                  {!isMobile && (
                    <Collapse isOpen={isOpen} transition="height 300ms linear" id={LISTBOX_ID} role="dialog">
                      <GlobalSearchDropdown
                        isOpen={isOpen}
                        searchQuery={query}
                        onCloseButtonClick={handleClickOutside}
                        isTyping={isTyping}
                        isError={isError}
                        fastMatcherData={fastMatcherData}
                        isFastMatcherLoading={isFastMatcherLoading}
                        searchId={searchId}
                        isSelectOptionMessageShown={isSelectOptionMessageShown}
                      />
                    </Collapse>
                  )}
                </Form>
              </div>
            </div>
          </FocusRing>
        </FocusScope>
      </RestoreFocus>
      {isMobile && (
        <GlobalSearchDrawer
          id={LISTBOX_ID}
          isOpen={isOpen}
          onDrawerCancel={handleOnDrawerCancel}
          fastMatcherData={fastMatcherData}
          isFastMatcherLoading={isFastMatcherLoading}
          query={query}
          setQuery={setQuery}
          isMobile
          withEnterSubmitDisabled={withEnterSubmitDisabled}
          onSearchSubmit={onSearchSubmit}
          onAfterSubmit={handleAfterDrawerSubmit}
        />
      )}
      {!isMobile && isLoading && loadingComponent}
    </div>
  );
}

GlobalSearchUI.propTypes = {
  setModelValue: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  withEnterSubmitDisabled: PropTypes.bool,
  suggestedFilters: PropTypes.shape({
    filters: PropTypes.arrayOf(InventoryEntities.FacetValue),
    inventoryCount: PropTypes.number,
    modifiedRadius: PropTypes.number,
    identifiedVin: PropTypes.shape({
      vin: PropTypes.string,
      vinInfo: InventoryEntities.InventoryVin,
      styleInfo: PropTypes.arrayOf(
        PropTypes.shape({
          makeNiceId: PropTypes.string,
          modelNiceId: PropTypes.string,
          year: PropTypes.number,
          styleId: PropTypes.number,
        })
      ),
    }),
  }),
  searchId: PropTypes.string,
  apiMetrics: ApiMetrics,
  onSearchSubmit: PropTypes.func,
  closeOnSubmit: PropTypes.bool,
};

GlobalSearchUI.defaultProps = {
  isMobile: false,
  withEnterSubmitDisabled: false,
  suggestedFilters: {},
  searchId: '',
  apiMetrics: {},
  onSearchSubmit: undefined,
  closeOnSubmit: false,
};

export const stateToPropsConfig = {
  suggestedFilters: bindToPath(
    ({ searchId }) => (searchId ? `suggestedFilters["${searchId}"]` : null),
    SemanticSearchModel,
    transformSuggestedFilters,
    false
  ),
};

const searchIdConnector = {
  searchId: bindToPath(({ searchRoute }) => buildSearchIdPath(searchRoute), SemanticSearchModel),
};

export const GlobalSearch = flow(
  withMetrics,
  component => connectToModel(component, stateToPropsConfig),
  component => connectToModel(component, searchIdConnector)
)(GlobalSearchUI);
