import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { DrawerOnDemand } from 'site-modules/shared/components/drawer/drawer-on-demand';
import { GlobalSearchDialogContent } from 'site-modules/shared/components/inventory/global-search/global-search-dialog-content/global-search-dialog-content';

export const GlobalSearchDrawer = memo(
  ({
    isOpen,
    id,
    onDrawerCancel,
    isMobile,
    fastMatcherData,
    isFastMatcherLoading,
    query,
    setQuery,
    withEnterSubmitDisabled,
    onSearchSubmit,
    onAfterSubmit,
  }) => (
    <DrawerOnDemand
      isOpen={isOpen}
      toggle={noop}
      side="bottom"
      size="small"
      id={id}
      noInitialFocus
      classNames={{
        drawerContainer: 'h-100 d-flex',
        drawerContent: 'w-100',
      }}
    >
      <GlobalSearchDialogContent
        onCancel={onDrawerCancel}
        isMobile={isMobile}
        fastMatcherData={fastMatcherData}
        isFastMatcherLoading={isFastMatcherLoading}
        query={query}
        setQuery={setQuery}
        withEnterSubmitDisabled={withEnterSubmitDisabled}
        onSearchSubmit={onSearchSubmit}
        onAfterSubmit={onAfterSubmit}
      />
    </DrawerOnDemand>
  )
);

GlobalSearchDrawer.propTypes = {
  isOpen: PropTypes.bool,
  withEnterSubmitDisabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onDrawerCancel: PropTypes.func,
  isMobile: PropTypes.bool,
  fastMatcherData: PropTypes.shape({
    delta: PropTypes.string,
    struct: PropTypes.shape({}),
  }),
  isFastMatcherLoading: PropTypes.bool,
  query: PropTypes.string,
  setQuery: PropTypes.func,
  onSearchSubmit: PropTypes.func,
  onAfterSubmit: PropTypes.func,
};

GlobalSearchDrawer.defaultProps = {
  isOpen: false,
  withEnterSubmitDisabled: false,
  onDrawerCancel: null,
  isMobile: false,
  fastMatcherData: null,
  isFastMatcherLoading: false,
  query: '',
  setQuery: noop,
  onSearchSubmit: undefined,
  onAfterSubmit: noop,
};
