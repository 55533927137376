import React, { useCallback, useDeferredValue, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { noop, flow } from 'lodash';
import classnames from 'classnames';
import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
/* Constants */
import { OPEN_ANIMATION } from 'site-modules/shared/components/drawer/drawer';
import {
  CLEAR_INPUT,
  INPUT_DELAY,
  AUTOSIZED_AREA_LENGTH,
  LOOKING_FOR_PLACEHOLDER,
  CREATIVE_ID,
} from 'site-modules/shared/constants/global-search/global-search';
import { KEY_CODES } from 'site-modules/shared/components/home-vehicle-search-autocomplete/home-vehicle-search-autocomplete';
/* Utils */
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { transformSuggestedFilters } from 'site-modules/shared/utils/inventory/semantic-search';
import {
  fireAbandonTracking,
  fireResetTracking,
  fireSearchLinkTracking,
  fireTemporaryKeydownTracking,
} from 'site-modules/shared/utils/inventory/global-search-tracking';
/* Hooks */
import { useTimeout } from 'site-modules/shared/hooks/use-timeout';
import { useLlmResults } from 'site-modules/shared/hooks/global-search/use-llm-results';
import { useDebounce } from 'site-modules/shared/hooks/use-debounce';
/* Models */
import { InventoryEntities } from 'client/data/models/inventory';
import { buildSearchIdPath, SemanticSearchModel } from 'client/data/models/semantic-search';
/* Components */
import { GlobalSearchResults } from 'site-modules/shared/components/inventory/global-search/global-search-results/global-search-results';

import './global-search-dialog-content.scss';

function GlobalSearchDialogContentUI({
  onCancel,
  setModelValue,
  suggestedFilters,
  searchId,
  fastMatcherData,
  isFastMatcherLoading,
  query,
  setQuery,
  withEnterSubmitDisabled,
  onSearchSubmit,
  onAfterSubmit,
}) {
  const deferredQuery = useDeferredValue(query);
  const trimmedQuery = deferredQuery.trim();
  const [isTyping, setIsTyping] = useState(false);
  const [isSelectOptionMessageShown, setIsSelectOptionMessageShown] = useState(false);
  const debouncedIsTyping = useDebounce(setIsTyping, INPUT_DELAY);
  const inputRef = useRef();
  const [drawerAnimationTimeout, clearDrawerAnimationTimeout] = useTimeout();
  const { triggerSubmit, triggerChange, isLoading, isError, loadingComponent } = useLlmResults({
    suggestedFilters,
    searchId,
    setModelValue,
    query: trimmedQuery,
  });
  const prevSearchQuery = useRef('');

  useEffect(() => {
    drawerAnimationTimeout(() => {
      inputRef.current?.focus();
    }, OPEN_ANIMATION);

    return () => {
      clearDrawerAnimationTimeout();
    };
  }, [clearDrawerAnimationTimeout, drawerAnimationTimeout]);

  const handleInputChange = useCallback(
    async ({ target: { value } }) => {
      setQuery(value);
      setIsTyping(true);
      debouncedIsTyping(false);
      setIsSelectOptionMessageShown(false);
      await triggerChange();
    },
    [debouncedIsTyping, setQuery, triggerChange]
  );

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();
      fireSearchLinkTracking({
        input: trimmedQuery,
        fastMatcherData,
        value: `search ${trimmedQuery}`,
        selectionType: 'search',
      });
      setIsSelectOptionMessageShown(false);

      if (onSearchSubmit) {
        onSearchSubmit(trimmedQuery);
      } else {
        await triggerSubmit(trimmedQuery);
      }

      onAfterSubmit();
    },
    [trimmedQuery, fastMatcherData, onSearchSubmit, onAfterSubmit, triggerSubmit]
  );

  const onQueryCancel = useCallback(async () => {
    await triggerChange();
    setQuery('');
    setIsSelectOptionMessageShown(false);
    inputRef.current?.focus();
    fireResetTracking({ input: trimmedQuery, fastMatcherData });
  }, [triggerChange, setQuery, trimmedQuery, fastMatcherData]);

  const handleKeyDown = useCallback(
    async event => {
      if (event.keyCode === KEY_CODES.escape) {
        onCancel();
      }

      if (event.keyCode === KEY_CODES.enter) {
        event.preventDefault();
        if (withEnterSubmitDisabled) {
          setIsSelectOptionMessageShown(true);
        } else if (prevSearchQuery.current !== trimmedQuery) {
          prevSearchQuery.current = trimmedQuery;
          await handleSubmit(event);
        }
      }

      fireTemporaryKeydownTracking(event);
    },
    [handleSubmit, onCancel, trimmedQuery, withEnterSubmitDisabled]
  );

  const handleCancel = useCallback(async () => {
    fireAbandonTracking({ input: deferredQuery, fastMatcherData });
    onCancel();
  }, [onCancel, deferredQuery, fastMatcherData]);

  return (
    <div className="global-search-dialog-content bg-white p-1">
      <Form
        name="global-search-dialog-form"
        onSubmit={handleSubmit}
        onKeyDown={handleKeyDown}
        data-tracking-parent={CREATIVE_ID}
        noValidate
      >
        <div className="d-flex align-items-center mb-1">
          <Button onClick={handleCancel} className="p-0 mr-1 border-0 background-none">
            <i className="text-cool-gray-50 icon-arrow-left3" aria-hidden />
          </Button>
          <div className="global-search-input pos-r w-100">
            <Input
              type="text"
              id="global-search-input"
              name="global-search-input"
              autoComplete="off"
              placeholder={LOOKING_FOR_PLACEHOLDER}
              className={classnames('search-field h-100 size-16 bg-cool-gray-80 rounded-12 border-0', {
                'pt-1_75 pl-0_75 pr-2_5 with-text': !!query,
                'py-0_5 px-0_75': !query,
              })}
              value={query}
              onChange={handleInputChange}
              maxLength={AUTOSIZED_AREA_LENGTH}
              innerRef={inputRef}
            />
            <label className="search-label" htmlFor="global-search-input">
              {LOOKING_FOR_PLACEHOLDER}
            </label>
            {!!query && (
              <Button
                onClick={onQueryCancel}
                className="query-cancel-btn pos-a p-0 border-0 background-none d-flex justify-content-center align-items-center"
              >
                <i className="text-cool-gray-50 icon-cross2 small" role="img" aria-label={CLEAR_INPUT} />
              </Button>
            )}
          </div>
        </div>
        <GlobalSearchResults
          searchQuery={deferredQuery}
          isTyping={isTyping}
          isError={isError}
          fastMatcherData={fastMatcherData}
          isFastMatcherLoading={isFastMatcherLoading}
          searchId={searchId}
          isMobile
          isSelectOptionMessageShown={isSelectOptionMessageShown}
        />
      </Form>
      {isLoading && loadingComponent}
    </div>
  );
}

GlobalSearchDialogContentUI.propTypes = {
  onCancel: PropTypes.func,
  setModelValue: PropTypes.func,
  suggestedFilters: PropTypes.shape({
    filters: PropTypes.arrayOf(InventoryEntities.FacetValue),
    inventoryCount: PropTypes.number,
    modifiedRadius: PropTypes.number,
    identifiedVin: PropTypes.shape({
      vin: PropTypes.string,
      vinInfo: InventoryEntities.InventoryVin,
      styleInfo: PropTypes.arrayOf(
        PropTypes.shape({
          makeNiceId: PropTypes.string,
          modelNiceId: PropTypes.string,
          year: PropTypes.number,
          styleId: PropTypes.number,
        })
      ),
    }),
  }),
  searchId: PropTypes.string,
  fastMatcherData: PropTypes.shape({
    delta: PropTypes.string,
    struct: PropTypes.shape({}),
  }),
  isFastMatcherLoading: PropTypes.bool,
  withEnterSubmitDisabled: PropTypes.bool,
  query: PropTypes.string,
  setQuery: PropTypes.func,
  onSearchSubmit: PropTypes.func,
  onAfterSubmit: PropTypes.func,
};

GlobalSearchDialogContentUI.defaultProps = {
  onCancel: noop,
  setModelValue: noop,
  suggestedFilters: {},
  searchId: '',
  fastMatcherData: null,
  isFastMatcherLoading: false,
  withEnterSubmitDisabled: false,
  query: '',
  setQuery: noop,
  onSearchSubmit: undefined,
  onAfterSubmit: noop,
};

const searchResultsConnector = {
  suggestedFilters: bindToPath(
    ({ searchId }) => (searchId ? `suggestedFilters["${searchId}"]` : null),
    SemanticSearchModel,
    transformSuggestedFilters,
    false
  ),
};

const searchIdConnector = {
  searchId: bindToPath(({ searchRoute }) => buildSearchIdPath(searchRoute), SemanticSearchModel),
};

export const GlobalSearchDialogContent = flow(
  component => connectToModel(component, searchResultsConnector),
  component => connectToModel(component, searchIdConnector)
)(GlobalSearchDialogContentUI);
